.nav {
  position: fixed;
  height: 84px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: $navy;
  transition: transform 0.4s ease-in-out;

  &_hidden {
    transform: translateY(-100%);
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
    &_active {
      position: fixed;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      padding-top: 23px;
      color: $white;
      background: $navy;
      z-index: 5;
      animation: fadein 0.5s;
      .nav {
        &__logo {
          margin-left: 30px;
          align-self: flex-start;
        }
        &__btn {
          position: absolute;
          top: 20px;
          right: 30px;
          .nav__btn-text {
            display: none;
          }
          &::before {
            top: auto;
            transform: rotate(45deg);
          }
          &::after {
            top: auto;
            transform: rotate(-45deg);
          }
        }
        &__links.footer-links {
          display: flex;
          width: 100%;
        }
      }

      .menu {
        display: block;
        order: 3;
        max-width: none;
        height: auto;
        justify-content: center;
        &__item {
          text-align: center;
          padding-bottom: 42px;
          &:last-child {
            padding-bottom: 0px;
          }
        }
        &__link {
          text-transform: capitalize;
          font-size: 36px;
          font-weight: 800;
          line-height: 40px;
          &.btn {
            border: none;
            height: auto;
            width: auto;
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  &__logo {
    flex-shrink: 0;
    order: 1;
    margin-right: 40px;
  }

  &__btn {
    display: none;
    position: relative;
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    background: transparent;
    font-family: "Montserrat", serif;
    font-size: 8px;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 2.3px;
    text-transform: uppercase;
    padding: 0;
    height: 25px;
    width: 30px;
    order: 3;
    cursor: pointer;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      transition: transform 0.2s ease-in-out;
    }
    &::before {
      top: 0px;
    }
    &::after {
      top: 7px;
    }
    &_light {
      color: $white;
      &::before,
      &::after {
        background-color: $white;
      }
    }
    &_dark {
      color: $blue;
      &::before,
      &::after {
        background-color: $blue;
      }
    }
  }

  &__btn-text {
    display: block;
    padding-top: 15px;
  }

  &__links.footer-links {
    order: 4;
    display: none;
  }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  order: 2;
  height: 100%;
  &__mobile-img {
    display: none;
  }
  &__item {
    list-style-type: none;
    height: 40px;
    margin: 0 0 0 63px;
    @media screen and (max-width: $menuBreak) {
      margin: 0 0 0 30px;
    }
    @media screen and (max-width: $tablet) {
      margin: 0;
    }
    &:first-child {
      margin: 0;
    }
    &:last-child {
      @media screen and (max-width: $tablet-max) {
        margin: 0 0 0 30px;
      }
      @media screen and (max-width: $tablet) {
        margin: 0;
      }
    }
  }
  &__link {
    display: block;
    position: relative;
    color: $white;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: 500;
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
    transition: color 0.2s;
    &.btn {
      line-height: 40px;
      height: 40px;
      padding-left: 0px;
      padding-right: 0px;
      width: 157px;
      text-align: center;
      transition: all 0.2s;
      &:hover {
        color: $white;
      }
    }
    &:hover {
      color: $orange;
    }
    &_active {
      color: $orange;
      &.btn {
        color: $white;
      }
    }
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@media screen and (max-width: $tablet) {
  .nav {
    height: 80px;
    &__wrap {
      &_active {
        padding-left: 0px;
        padding-right: 0px;
        @media screen and (orientation: landscape) {
          flex-wrap: nowrap;
          overflow-y: auto;
          .footer-links,
          .menu_light {
            margin: 42px 0 0;
          }
          @media screen and (max-width: $mobile) {
            .menu_light {
              margin: 0;
            }
          }
        }
      }
    }

    &__btn {
      display: block;
    }
  }

  .menu {
    display: none;
    &__item {
      height: auto;
    }
    &__link {
      height: auto;
      &.btn {
        &:hover {
          color: $orange;
        }
  
        &:active {
          transform: none;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .nav {
    height: 60px;
    &__wrap {
      padding-left: 15px;
      padding-right: 15px;
    }
    &__wrap_active {
      color: $white;
      padding-left: 0 !important;
      padding-right: 0 !important;
      .nav {
        &__logo {
          visibility: hidden;
        }
        &__btn {
          top: 10px;
          right: 15px;
        }
      }
      .menu {
        &__item {
          padding-bottom: 25px;
        }
        &__link {
          font-size: 24px;
          letter-spacing: normal;
        }
      }
    }
  }
}
