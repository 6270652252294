.cta {
  background-color: $light-blue;
  padding-top: 43px;
  padding-bottom: 43px;
  &__wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 1050px;
    margin-right: auto;
    width: 100%;
  }
  .title-h2 {
    margin-right: auto;
  }
  .btn_light {
    margin-right: 26px;
  }
}

@media screen and (max-width: $tablet) {
  .cta {
    padding-top: 38px;
    padding-bottom: 40px;
    &__wrap {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .title-h2 {
      width: 100%;
      font-size: 44px;
      padding-bottom: 35px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .cta {
    padding-top: 45px;
    padding-bottom: 50px;
    .title-h2 {
      font-size: 36px;
      padding-bottom: 27px;
    }
    .text-orange {
      display: block;
    }
    .btn_light,
    .btn_orange {
      width: 100%;
      text-align: center;
    }
    .btn_light {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}
