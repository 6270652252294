html, body {
  font-family: "Montserrat", serif;
  overflow-x: hidden;
  margin: 0;
}

body.fixed {
  overflow: hidden;
  position: fixed;
}

main {
  margin: auto;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
  outline-style: none;
}

button {
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  background: none;
  outline: none;
  outline-style: none;
}

h1, h2, h3, h4, h5, h6, ul {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

p {
  margin: 0;
}

* {
  outline: none;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $navy;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  &_stoped {
    display: none;
  }

  svg {
    display: block;
    width: 60px;
    height: 60px;
  }

  .circle {
    animation-name: animateCircle;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    transform-origin: center;
    &_group2 {
      animation-delay: 0.19s;
    }
    &_group3 {
      animation-delay: 0.39s;
    }
    &_group4 {
      animation-delay: 0.58s;
    }
  }
}

@keyframes animateCircle {
  0% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
  33% {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 0.9);
  }
  66% {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 0.9);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.header__wrap {
  padding-top: 84px;
}

.container,
.container-big,
.container-max {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.container {
  max-width: 1230px;
  padding-left: 30px;
  padding-right: 30px;
}

.container-big {
  max-width: 1440px;
}

.container-max {
  max-width: 1920px;
}

@media screen and (max-width: $tablet) {
  .header__wrap {
    padding-top: 80px;
  }
}
@media screen and (max-width: $mobile) {
  .header__wrap {
    padding-top: 50px;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
