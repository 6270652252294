.footer {
  background: $navy;
  color: $white;
  &__wrap {
    display: flex;
    justify-content: space-between;
  }
  &__logo {
    flex-shrink: 0;
    margin-right: 20px;
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    margin-right: 28px;
    max-width: 554px;
    width: 100%;
  }
  &__container {
    padding-top: 80px;
    padding-bottom: 87px;
  }
  &__copyright {
    font-size: 14px;
    line-height: 18px;
    opacity: 0.5;
  }
}

.footer-menu {
  list-style-type: none;
  &__item {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  &__link {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 12px;
    transition: color 0.2s;
    &:hover {
      color: $orange;
    }
  }
}

.footer-links {
  &__item {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    padding-bottom: 27px;
  }
}

.social-links {
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: space-between;
  max-width: 100px;
  font-size: 13px;
  padding-top: 3px;
  padding-bottom: 25px;
  &__link::before {
    transition: color 0.2s;
  }

  &__link:hover::before {
    color: $orange;
  }

  .icon-be {
    font-size: 10px;
  }
}



@media screen and (max-width: $tablet) {
  .footer {
    &__container {
      padding: 0;
      background-image: none;
    }
    &__nav {
      display: block;
      max-width: 100%;
      margin-right: 0;
    }
    &__wrap {
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-right: 0px;
      padding-left: 0px;
    }
    .logo {
      display: none;
    }
    &__info {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
    &__copyright {
      text-align: center;
      padding-top: 51px;
      padding-bottom: 28px;
    }
  }
  .footer-menu {
    width: 100%;
    display: flex;
    padding-top: 41px;
    padding-bottom: 41px;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    &__item {
      margin-bottom: 0px;
    }
  }
  .footer-links {
    display: flex;
    justify-content: flex-start;
    border-top: 1px solid rgba(255,255,255,0.1);
    border-bottom: 1px solid rgba(255,255,255,0.1);
    height: 59px;
    &__item {
      padding-bottom: 0;
      text-align: center;
      line-height: 59px;

      &:nth-child(1) {
        max-width: 185px;
        width: 100%;
      }
      &:nth-child(2){
        border-left: 1px solid rgba(255,255,255,0.1);
        max-width: 219px;
        width: 100%;
      }
    }
  }
  .social-links {
    padding-bottom: 0px;
    padding-top: 0px;
    max-width: 100%;
    width: 100%;
    &__item {
      width: 33.33%;
      height: 100%;
      flex-shrink: 0;
      text-align: center;
      line-height: 59px;
      border-left: 1px solid rgba(255,255,255,0.1);
    }
  }
}

@media screen and (max-width: $mobile) {
  .footer {
    &__copyright {
      padding-top: 17px;
      padding-bottom: 19px;
    }
  }
  .footer-menu {
    flex-wrap: wrap;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 45px;
    padding-bottom: 36px;
    &__item {
      width: 100%;
      text-align: center;
      margin-bottom: 41px;
    }
    &__link {
      font-size: 14px;
    }
  }
  .footer-links {
    flex-wrap: wrap;
    height: auto;
    &__item {
      line-height: 49px;
      border-bottom: 1px solid rgba(255,255,255,0.1);
      &:nth-child(1),
      &:nth-child(2) {
        max-width: none;
      }
    }
  }
  .social-links {
    max-width: none;
    &__item {
      line-height: 49px;
    }
  }
}
