.portfolio {
  position: relative;

  &__wrap {
    padding-top: 120px;
    padding-bottom: 157px;
  }

  &__title {
    width: 50%;
    margin-left: auto;
    .text-striped {
      padding-bottom: 20px;
    }
    .title-h1 {
      padding-bottom: 56px;
    }
  }

  &__slider {
    position: relative;
    display: flex;
  }

  &__content {
    width: 50%;
    padding-bottom: 100px;
  }

  .slick-list {
    overflow: hidden;
    height: 100%;
  }

  .slick-track {
    display: flex;
    height: 100%;
  }

  .slick-slide {
    opacity: 0;
    transition: opacity 0.4s ease-in;
    &.slick-active {
      opacity: 1;
    }
  }

  .slick-slide > div {
    height: 100%;
  }
}

.portfolio-nav {
  position: absolute;
  left: 50%;
  bottom: 157px;
  display: flex;
  height: 50px;
  width: 222px;
  line-height: 50px;

  &__btn {
    width: 50px;
    height: 100%;
    margin: 0;
    background-color: $orange;
    background-image: url('../../img/arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0 3px 3px 0;
    transform: none;
    cursor: pointer;

    &_prev {
      transform: rotate(180deg);
      order: 1;
    }

    &_next {
      margin-left: 1px;
      order: 2;
    }

    &_prev[aria-disabled="true"],
    &_next[aria-disabled="true"] {
      background-color: #D7DAE9;
    }
  }

  &__item {
    font-size: 12px;
    line-height: 50px;
    letter-spacing: 1.2px;
    font-weight: bold;
    color: #D7DAE9;

    &_prev {
      margin-left: auto;
      order: 3;
      &::after {
        content: '';
        display: inline-block;
        height: 1px;
        width: 36px;
        background-color: #E5E5EB;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: -1px;
        vertical-align: middle;
      }
    }

    &_next {
      order: 4;
    }

    &_active {
      color: #FF875F;
    }
  }
}

.portfolio-img {
  position: relative;
  width: 50%;
  height: 445px;
  z-index: 1;

  &__mobile,
  &__desktop {
    position: absolute;
    overflow: hidden;
  }

  &__desktop {
    width: 720px;
    right: 120px;
  }

  &__mobile {
    right: 70px;
    bottom: 0;
  }

  &__mac,
  &__iphone {
    display: block;
    width: 100%;
    height: auto;
  }
}

.portfolio-slider {
  position: absolute;
  overflow: hidden;
  &_desktop {
    top: 7%;
    left: 12%;
    width: 76%;
  }
  &_mobile {
    top: 11%;
    left: 6%;
    width: 88%;
  }
  &__item img {
    width: 100%;
    height: auto;
  }
}

.portfolio-item {
  display: flex!important;
  flex-direction: column;
  height: 100%;

  &__text {
    padding-bottom: 20px;
    .text-small {
      color: $dark-grey;
    }
  }
  
  &__name {
    display: block;
    color: $orange;
    font-size: 36px;
    font-weight: 800;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 40px;
    padding-bottom: 25px;
  }

  &__dashboard {
    margin-top: auto;
  }
}

.kpi {
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  width: 100%;
  &__text {
    display: block;
    color: $blue;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 21px;
  }
  &__number {
    display: block;
    color: $orange;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 21px;
  }
}

.dashboard img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: $tablet) {
  .portfolio {
    &__wrap {
      padding-top: 65px;
      padding-bottom: 120px;
    }

    &__content {
      width: 100%;
      padding-bottom: 0px;
    }

    &__title {
      margin-left: 0;
      .title-h1 {
        padding-bottom: 37px;
      }
    }
  }

  .portfolio-img {
    position: absolute;
    width: 69%;
    height: 363px;

    &__desktop {
      right: 100px;
      width: 590px;
    }

    &__mobile {
      right: 62px;
      width: 120px;
    }
  }

  .portfolio-item {
    padding-right: 0px;
    align-items: flex-end;

    &__name {
      width: 215px;
      order: 1;
      font-size: 24px;
      letter-spacing: 2px;
      padding-bottom: 31px;
    }

    &__text {
      order: 3;
      padding-bottom: 0px;
    }

    &__dashboard {
      width: 215px;
      order: 2;
      padding-bottom: 165px;
      margin-top: 0;
    }
  }

  .kpi {
    flex-wrap: wrap;

    &__item {
      width: 100%;
      margin-bottom: 21px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &__text {
      font-weight: bold;
    }

    &__number {
      font-weight: bold;
    }
  }

  .portfolio-nav {
    height: 45px;
    width: 210px;
    top: 515px;
    left: auto;
    right: 35px;
    line-height: 45px;
    justify-content: center;
    background-color: #F0F6FC;

    &__btn {
      width: 45px;
      &_prev {
        margin-right: auto;
      }

      &_next {
        order: 4;
        margin-left: auto;
      }
    }

    &__item {
      line-height: 45px;
      &_prev {
        order: 2;
        margin-left: 0;
        &::after {
          content: '/';
          height: auto;
          width: auto;
          background-color: unset;
          color: #D7DAE9;
          margin-left: 5px;
          margin-right: 5px;
        }
      }

      &__next {
        order: 3;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .portfolio {
    &__wrap {
      padding-top: 60px;
      padding-bottom: 94px;
    }

    &__title {
      width: 100%;
      .title-h1 {
        padding-bottom: 32px;
      }
    }
  }

  .portfolio-img {
    height: 310px;
    top: 340px;
    width: 100%;

    &__desktop {
      width: 500px;
      right: 52px;
    }

    &__mobile {
      right: 0;
    }
  }

  .portfolio-item {
    &__name {
      width: 100%;
      line-height: 24px;
      padding-bottom: 21px;
    }

    &__dashboard {
      width: 100%;
      padding-bottom: 0px;
      margin-bottom: 475px;
      min-height: 162px;
    }
  }

  .kpi {
    display: block;
    max-width: 100%;
    -webkit-text-size-adjust: 100%;
    &__item {
      margin-bottom: 18px;
    }
  }

  .portfolio-nav {
    width: calc(100% - 30px);
    right: auto;
    left: 15px;
    top: 420px;
  }
}
