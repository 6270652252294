@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icons/icomoon.eot?5vorep');
  src:  url('../../fonts/icons/icomoon.eot?5vorep#iefix') format('embedded-opentype'),
    url('../../fonts/icons/icomoon.ttf?5vorep') format('truetype'),
    url('../../fonts/icons/icomoon.woff?5vorep') format('woff'),
    url('../../fonts/icons/icomoon.svg?5vorep#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\e900";
  color: #6d718c;
}
.icon-arrow-hover:before {
  content: "\e901";
  color: #ff875f;
}
.icon-be:before {
  content: "\e902";
  color: #fff;
}
.icon-big-arrow:before {
  content: "\e903";
  color: #fff;
}
.icon-dribble:before {
  content: "\e904";
  color: #fff;
}
.icon-dwnload:before {
  content: "\e905";
  color: #757575;
}
.icon-facebook:before {
  content: "\e906";
  color: #fff;
}
.icon-info:before {
  content: "\e907";
  color: #6d718c;
}
.icon-insta:before {
  content: "\e908";
  color: #fff;
}
.icon-lnkdin:before {
  content: "\e909";
  color: #fff;
}
.icon-quote:before {
  content: "\e90a";
  color: #ff875f;
}
.icon-shape:before {
  content: "\e90b";
  color: #ff875f;
}
