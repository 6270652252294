.nav__wrap {
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.34,0.01,0,0.98);
  transition-duration: 750ms;
  &.animate {
    transition: none;
    transform: translateY(50px);
    opacity: 0;
  }
}

.heading {
  .text-striped,
  .title-h1,
  .text-small,
  .presentation__title,
  .presentation__btn,
  .btn_heading,
  &__reasons {
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.34,0.01,0,0.98);
    transition-duration: 750ms;
  }
  .title-h1,
  .presentation__btn {
    transition-delay: 0.1s
  }
  .text-small {
    transition-delay: 0.2s;
  }
  .btn_heading,
  &__reasons {
    transition-delay: 0.3s;
  }

  &.animate {
    .text-striped,
    .title-h1,
    .text-small,
    .presentation__title,
    .presentation__btn,
    .heading__reasons,
    .btn_heading {
      transition: none;
      transform: translateY(50px);
      opacity: 0;
    }
  }
}

.heading-img {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.34,0.01,0,0.98);
  transition-duration: 750ms;
  transition-delay: 0.4s;
  &.animate {
    transition: none;
    opacity: 0;
  }
}

.service {
  &__img {
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.34,0.01,0,0.98);
    transition-duration: 850ms;
  }

  &__info {
    .text-striped,
    .title-h1,
    .text-small,
    .tags {
      transition-property: opacity, transform;
      transition-timing-function: cubic-bezier(0.34,0.01,0,0.98);
      transition-duration: 750ms, 750ms;
    }
    .title-h1 {
      transition-delay: 0.1s
    }
    .text-small {
      transition-delay: 0.2s;
    }
    .tags {
      transition-delay: 0.3s;
    }
  }
  &.animate {
    .text-striped,
    .title-h1,
    .text-small,
    .tags,
    .service__img {
      transition: none;
      transform: translateY(50px);
      opacity: 0;
    }
  }
}

.e-commerce__wrap,
.portfolio__wrap,
.brands,
.e-commerce__section,
.e-commerce__table,
.stages-tablet__item,
.gallery__stage,
.specialization__wrap,
.spec-block__item,
.careers__title,
.careers-tabs,
.studio-carousel__items {
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.34,0.01,0,0.98);
  transition-duration: 750ms, 750ms;
  &.animate {
    transition: none;
    transform: translateY(50px);
    opacity: 0;
  }
}

.careers__title  {
  transition-delay: 0.3s;
}

.gallery__stage {
  transition-delay: 0.4s;
}

.careers-tabs {
  transition-delay: 0.4s;
}

.e-commerce__section {
  transition-delay: 0.3s;
}

.stages-tablet__item:first-child {
  transition-delay: 0.4s;
}

.team {
  .text-striped,
  .title-h1,
  .team__block,
  .btn_orange {
    transition-property: opacity, transform, color, background;
    transition-timing-function: cubic-bezier(0.34,0.01,0,0.98);
    transition-duration: 750ms, 750ms, 0.3s, 0.3s;
  }
  .title-h1{
    transition-delay: 0.1s;
  }
  .team__block {
    transition-delay: 0.2s;
  }
  .btn_orange {
    transition-delay: 0.3s, 0.3s, 0s, 0s;
  }

  &.animate {
    .text-striped,
    .title-h1,
    .team__block,
    .btn_orange {
      transition: none;
      transform: translateY(50px);
      opacity: 0;
    }
  }
}

.contacts {
  &__info,
  &__form {
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.34,0.01,0,0.98);
    transition-duration: 750ms;
    transition-delay: 0.2s;
  }

  &.animate {
    .contacts__info,
    .contacts__form {
      transition: none;
      transform: translateY(50px);
      opacity: 0;
    }
  }
}

@media screen and (max-width: $tablet) {
  .stages-tablet__item:first-child {
    transition-delay: 0s;
  }
}
