.title-h1 {
	color: $blue;
  font-size: 58px;
  font-weight: 800;
  line-height: 63px;
  &_light {
    color: $white;
  }
}
.title-h2 {
	color: $blue;
  font-size: 36px;
  font-weight: 800;
  line-height: 42px;
}
.title-h3 {
	color: $blue;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
}
.title-h4 {
	color: $orange;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}
.title-h5 {
	color: $blue;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
}
.text {
  color: $blue;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.text-small {
  color: $blue;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  &_light {
    color: $white;
  }
}
.text-orange {
  color: $orange;
}
.text-striped {
  position: relative;
  display: inline-block;
  color: $white;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  opacity: 0.5;
	padding-bottom: 30px;
	&__text {
		display: inline-block;
		&::before {
	    content: '';
	    display: inline-block;
	    width: 47px;
	    height: 1px;
	    background-color: $white;
	    opacity: 0.5;
	    margin-right: 20px;
	    margin-bottom: 2px;
			vertical-align: middle;
	  }
	}
	&__number {
		margin-right: 20px;
	}
  &_dark {
    color: #6D718C;
		.text-striped__text {
			&::before {
				background-color: #BABBC9;
			}
		}
  }
}

@media screen and (max-width: $tablet) {
	.title-h1 {
	  font-size: 44px;
	  line-height: 50px;
	}
	.text-striped {
		padding-bottom: 20px;
	}
}

@media screen and (max-width: $mobile) {
	.title-h1 {
	  font-size: 36px;
	  line-height: 40px;
	}
	.text-striped {
		font-size: 10px;
		padding-bottom: 10px;
		&__text {
			&::before {
				width: 30px;
				margin-right: 10px;
			}
		}
		&__number {
			margin-right: 3px;
		}
	}
}
