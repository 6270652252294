@font-face {
  font-family: 'Geometria';
  src: url('../fonts/geometria/Geometria-Thin.woff2') format('woff2'),
      url('../fonts/geometria/Geometria-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('../fonts/geometria/Geometria-ExtraLight.woff2') format('woff2'),
      url('../fonts/geometria/Geometria-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('../fonts/geometria/Geometria-Light.woff2') format('woff2'),
      url('../fonts/geometria/Geometria-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('../fonts/geometria/Geometria.woff2') format('woff2'),
      url('../fonts/geometria/Geometria.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('../fonts/geometria/Geometria-Medium.woff2') format('woff2'),
      url('../fonts/geometria/Geometria-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('../fonts/geometria/Geometria-Bold.woff2') format('woff2'),
      url('../fonts/geometria/Geometria-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('../fonts/geometria/Geometria-ExtraBold.woff2') format('woff2'),
      url('../fonts/geometria/Geometria-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('../fonts/geometria/Geometria-Heavy.woff2') format('woff2'),
      url('../fonts/geometria/Geometria-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
