$desktop: 1170px;
$desktopBig: 1440px;
$tablet: 970px;
$tablet-max: 1024px;
$mobile: 640px;
$navy: #1B1E37;
$white: #ffffff;
$blue: #414562;
$orange: #FF875F;
$sienna: #E97650;
$dark-grey: #6D718C;
$light-blue: #F0F6FC;
$gray-suit: #BABBC9;
$ghost: #C5C6D1;
$purple: #D7DAE9;
$table-border: 1px solid #EAEFF6;
$menuBreak: 1100px;
