.btn {
  cursor: pointer;
  display: inline-block;
  position: relative;
  height: 45px;
  padding-left: 30px;
  padding-right: 30px;
  font-family: "Montserrat", serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 3px;
  border: 1px solid $orange;
  transition: all 0.3s;
  white-space: nowrap;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;

  &_orange {
    color: $white;
    background: $orange;

    &:hover {
      color: $blue;
      background: transparent;
    }

    &:active {
      color: $blue;
      background: transparent;
      transform: scale(0.98);
    }
    &--no-border {
      box-sizing: border-box;
      padding: 0 29px;
    }
  }

  &_light {
    color: $blue;
    background: transparent;

    &:hover {
      color: $white;
      background: $orange;
    }

    &:active {
      color: $white;
      background: $orange;
      transform: scale(0.98);
    }
  }

  &_orange-light {
    color: $white;
    background: $orange;

    &:hover {
      color: $blue;
      background: transparent;
    }

    &:active {
      color: $blue;
      background: transparent;
      transform: scale(0.98);
    }
  }
}

@media screen and (max-width: $mobile) {
  .btn {
    width: 100%;
    text-align: center;
  }
}
