.logo {
  display: block;
  width: 185px;
  height: 35px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  &_light {
    background-image: url('../../img/Logo.svg');
  }
  &_dark {
    background-image: url('../../img/Logo-dark.svg');
  }
  &_studio {
    background-image: url('../../img/logo-studio.svg');
    height: 31px;
    width: 145px;
  }
}

@media screen and (max-width: $mobile) {
  .logo {
    width: 144px;
    height: 25px;
  }
}
