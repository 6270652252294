.presentation {
  text-align: center;
  &__title {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 24px;
    padding-bottom: 76px;
  }
  &__btn {
    position: relative;
    display: inline-block;
    font-size: 50px;
    width: 97px;
    height: 97px;
    text-align: center;
    cursor: pointer;
    .icon-shape {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: $white;
      position: relative;
      line-height: 97px;
      z-index: 1;
    }
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 169px;
      height: 169px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      transition-property: width, height;
      transition-timing-function: cubic-bezier(0.34,0.01,0,0.98);
      transition-duration: 0.3s, 0.3s;
    }
    &:hover {
      .presentation__loader {
        width: 188px;
        margin-left: -94px;
        margin-top: -94px;
      }
      &::before {
        width: 149px;
        height: 149px;
      }
    }

  }
  &__loader {
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -104px;
    margin-top: -104px;
    width: 208px;
    transform-origin: center center;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.34,0.01,0,0.98);
    transition-duration: 0.3s, 0.3s;
    .loader-path {
      stroke-dasharray: 150,200;
      stroke-dashoffset: -10;
      stroke-linecap: round;
    }
  }
  &_light {
    .presentation {
      &__title {
        color: $white;
      }
      &__btn::before {
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid rgba(255,255,255,0.4);
      }
    }
  }
  &_dark {
    .presentation {
      &__title {
        color: $dark-grey;
      }
      &__btn::before {
        background-color: $light-blue;
        border: 1px solid $light-blue;
      }
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}

@keyframes color {
  0% {
    stroke: $orange;
  }
  40% {
    stroke: $orange;
  }
  66% {
    stroke: $orange;
  }
  80% {
    stroke: $orange;
  } 
  90% {
    stroke: $orange;
  }
}

@media screen and (max-width: $tablet) {
  .presentation {
    &__title {
      padding-bottom: 40px;
    }
    &__btn {
      font-size: 35px;
      width: 70px;
      height: 70px;
      .icon-shape {
        line-height: 70px;
      }
      &::before {
        width: 115px;
        height: 115px;
      }
      &:hover::before {
        width: 100px;
        height: 100px;
      }
    }
    &__loader {
      width: 125px;
      margin-left: -62.5px;
      margin-top: -62.5px;
    }
  }
}
