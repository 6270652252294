.header {
  background: $navy;
}

.heading {
  display: flex;
  justify-content: space-between;
  padding-top: 97px;
  padding-bottom: 50px;
  &__text {
    max-width: 601px;
    width: 100%;
    .text-striped {
      padding-bottom: 26px;
    }
    .title-h1 {
      max-width: 550px;
    }
    .text-small {
      padding-top: 37px;
    }
  }
  &__block {
    padding-right: 38px;
  }
  .btn_orange {
    display: none;
    &:hover,
    &:active {
      color: $white;
    }
  }
}

.heading-img {
  max-width: 1418px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  overflow: hidden;
  &__img {
    display: block;
    width: 100%;
    height: auto;
    transform: translateY(1px);
  }
}

.services {
  position: relative;
  margin-top: 15px;
  margin-bottom: 30px;
}

.services-nav {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  height: 70vh;
  list-style-type: none;
  &_fixed {
    position: fixed;
  }
  &_stop {
    position: absolute;
    top: auto;
    bottom: 0;
  }
  &__item {
    display: block;
    position: relative;
    color: $dark-grey;
    opacity: 0.4;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.4px;
    font-weight: 500;
    padding-left: 30px;
    margin-bottom: 31px;
    cursor: pointer;
    transition: opacity 0.2s;
    &:last-child {
      margin-bottom: 0px;
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 0px;
      height: 1px;
      background-color: $orange;
    }
    &:hover,
    &_active {
      opacity: 1;
      &::before {
        width: 10px;
      }
    }
  }
}

.service {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70vh;
  min-height: 600px;
  
  &__img {
    width: 50%;
    text-align: center;

    img {
      margin-right: 20px;
    }
    &_dev img {
      max-width: 465px;
    }
    &_content img {
      max-width: 463px;
    }
    &_digital img {
      max-width: 321px;
    }
    &_operational img {
      max-width: 359px;
    }
  }
  &__info {
    width: 50%;
    .title-h1 {
      max-width: 469px;
    }
    .text-striped {
      padding-bottom: 30px;
    }
    .text-small {
      padding-top: 40px;
      padding-bottom: 30px;
      color: $dark-grey;
    }
  }
}

.tags {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  &__item {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: $light-blue;
    color: $dark-grey;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 16px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 3px;
  }
}

.e-commerce {
  background: $navy;
  padding-top: 70px;
  padding-bottom: 70px;
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__text {
    max-width: 427px;
    .title-h1 {
      padding-bottom: 28px;
      line-height: 58px;
    }
  }

  .btn_orange {
    &:hover,
    &:active {
      color: $white;
    }
  }

  &__block {
    width: 50%;
    height: 400px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;

    canvas {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__img {
    display: none;
  }
}

@media screen and (max-width: 1390px) {
  .services-nav {
    display: none;
  }
}

@media screen and (max-width: $tablet) {
  .header {
    &::after {
      height: 344px;
    }
  }
  .heading {
    padding-top: 87px;
    &__text {
      max-width: 380px;
      .text-striped {
        padding-bottom: 20px;
      }
      .text-small {
        padding-top: 30px;
      }
    }
    &__block {
      padding-right: 0px;
    }
  }

  .services {
    padding-top: 105px;
    margin-top: 0px;
    margin-bottom: 0px;
    &__nav {
      display: none;
    }
  }
  .service {
    height: auto;
    min-height: auto;
    padding-bottom: 100px;
    &__info {
      .text-striped {
        padding-bottom: 20px;
      }
      .text-small {
        padding-top: 33px;
      }
    }
    &__img {
      margin-top: -70px;
      &_dev img {
        max-width: 315px;
      }

      &_content img {
        max-width: 322px;
      }

      &_digital img {
        max-width: 263px;
      }

      &_operational img {
        max-width: 294px;
      }
    }
  }

  .tags {
    &__item {
      position: relative;
      width: 100%;
      background-color: unset;
      margin-right: 0;
      margin-bottom: 15px;
      padding: 0;
      padding-left: 27px;
      font-size: 14px;
      font-weight: 400;
      color: $blue;
      text-transform: capitalize;
      letter-spacing: 1px;
      line-height: 20px;
      &::before {
        content: '';
        position: absolute;
        top: 4px;
        left: 0;
        display: block;
        width: 3px;
        height: 3px;
        background-color: $dark-grey;
        border: 4px solid $light-blue;
        border-radius: 50%;
        box-sizing: content-box;
      }
    }
  }

  .e-commerce {
    padding-top: 40px;
    padding-bottom: 40px;
    &__text {
      max-width: 330px;
      .title-h1 {
        padding-bottom: 38px;
        line-height: 52px;
      }
      .text-striped {
        padding-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .header {
    &__wrap {
      background-image: none;
    }
    &::after {
      display: none;
    }

  }
  .heading {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 70px;
    &__text {
      max-width: none;
      .text-small {
        padding-bottom: 32px;
        padding-top: 24px;
      }
      .text-striped {
        padding-bottom: 10px;
      }
    }
    &__block {
      display: none;
    }
    .btn_orange {
      display: block;
      text-align: center;
    }
  }
  .heading-img {
    display: none;
  }
  .services {
    padding-top: 74px;
    padding-bottom: 0px;
  }
  .service {
    flex-wrap: wrap;
    padding-bottom: 90px;
    &__img {
      width: 100%;
      padding-bottom: 22px;
      margin-top: 0;

      img {
        margin-right: 0;
      }

      &_digital img {
        max-width: 244px;
      }

      &_operational img {
        max-width: 288px;
      }
    }
    &__info {
      width: 100%;
      .text-small {
        padding-top: 25px;
        padding-bottom: 32px;
      }
      .text-striped {
        padding-bottom: 8px;
      }
    }
    &:last-child {
      padding-bottom: 55px;
    }
  }
  .e-commerce {
    padding-top: 0px;
    padding-bottom: 0px;
    &__wrap {
      flex-wrap: wrap;
    }
    &__container {
      padding-bottom: 60px;
    }
    &__text {
      max-width: none;
      width: 100%;
      order: 1;
      .title-h1 {
        padding-bottom: 40px;
        line-height: 40px;
      }
      .text-striped {
        padding-bottom: 10px;
      }
    }
    .btn_orange {
      width: 100%;
      text-align: center;
    }
    &__block {
      order: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      overflow: unset;
    }
  }
}

.content-production {
  display: flex;
  margin: 120px 0;
  position: relative;
  @media screen and (max-width: $mobile) {
    flex-direction: column;
    margin: 60px 0;
  }
  &__images {
    transform: translateX(-17px);
    width: 60.76%;
    @media screen and (max-width: 1240px) {
      transform: translateX(-100px);
    }
    @media screen and (max-width: $tablet) {
      transform: translateX(-85px);
      width: 55.6%;
    }
    @media screen and (max-width: $mobile) {
      align-items: center;
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      margin: 0 0 48px;
      order: 3;
      transform: translateX(-15px);
      width: fit-content;
    }
    &__row {
      display: flex;
      justify-content: center;
      margin: 0 0 10px;
      @media screen and (max-width: $tablet) {
        margin: 0 0 5px;
      }
      @media screen and (max-width: $mobile) {
        width: fit-content;
      }
      &:nth-child(even) {
        align-items: flex-start;
      }
      &:nth-child(odd) {
        align-items: flex-end;
      }
      &:last-child {
        margin: 0;
      }
      img {
        border-radius: 5px;
        display: block;
        margin: 0 5px;
        @media screen and (max-width: $tablet) {
          margin: 0 2.5px;
        }
      }
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 6.18%;
    width: 39.24%;
    @media screen and (max-width: $tablet) {
      padding: 0;
      transform: translateX(-40px);
      width: 44.4%;
    }
    @media screen and (max-width: $mobile) {
      display: contents;
      transform: unset;
      width: 100%;
    }
    .text-striped {
      padding-bottom: 15px;
      @media screen and (max-width: $mobile) {
        margin: 0 15px;
        order: 1;
        padding-bottom: 0;
      }
    }
    .title-h1 {
      margin: 0 0 40px;
      @media screen and (max-width: $tablet) {
        font-size: 58px;
        line-height: 63px;
      }
      @media screen and (max-width: $mobile) {
        font-size: 36px;
        line-height: 40px;
        margin: 0 15px 27px;
        order: 2;
      }
    }
    .btn {
      box-sizing: border-box;
      max-width: 160px;
      padding: 0;
      text-align: center;
      @media screen and (max-width: $mobile) {
        order: 4;
        margin: 0 15px;
        max-width: calc(100% - 30px);
      }
    }
  }
}
