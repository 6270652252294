.clients {
  align-items: center;
  background: $light-blue;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  @media screen and (max-width: $tablet-max) {
    padding: 30px 0;
  }
  @media screen and (max-width: $mobile) {
    padding: 30px 15px;
  }
  &__items {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin: 0 0 32px;
    padding: 0 9.24% 0 9.45%;
    width: 100%;
    @media screen and (max-width: $desktop) {
      padding: 0 30px;
    }
    @media screen and (max-width: $tablet-max) {
      margin: 0 0 35px;
    }
    @media screen and (max-width: $mobile) {
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 0 0 25px;
      padding: 0;
    }
    img {
      display: block;
      @media screen and (max-width: $tablet-max) {
        height: 40px;
        width: 71px;
      }
      @media screen and (max-width: $mobile) {
        height: 12.5vw;
        margin: 0 10px 5px 0;
        object-fit: contain;
        width: calc(100% / 3 - (20px / 3));
        &:nth-child(3n) {
          margin: 0 0 5px;
        }
      }
    }
  }
}
